@import '../../../assets/scss/colors';

.manage-licences__wrapper {
    margin-bottom: 24px;
}

.manage-licences__card {
    margin-bottom: 0.4em !important;

    & > div {
        padding: 0.4em 1em !important;
    }
}

.manage-licences__licence {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5, p, span {
        margin: 0 !important;
    }

    &__name {
        font-weight: 700;
        width: 20%;
    }

    &__email {
        width: 15%;
    }

    &__order {
        width: 25%;
    }

    &__status {
        width: 10%;
        span {
            padding: 0.5em;
            border-radius: 0.5em;
        }
        & .active {
            background-color: $primary-green;
            color: $dark-green;
        }
        & .pending {
            background-color: $button-light-grey;
        }
    }

    &__days-left {
        width: 5%;
    }

    &__action-buttons {
        height: 30px;
        button {
            outline: none;
            border: none;
            background-color: transparent;
            border-radius: 15%;
            &:hover {
                background-color: $button-hover-grey;
                color: $white;
            }
        }
    }
}

.manage-licences__assign-button {
    width: 25%;
    margin-top: 1em;
}