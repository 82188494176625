@import '../../../assets/scss/colors';

.toggleWrapper {
    background-color: $light-gray;
    border-radius: 2em;

    button {
        background-color: $light-gray;
        border-radius: 2em;
        position: relative;
        z-index: 1;


        &.selected {
            background-color: $purple-heart;
            z-index: 1000;
        }
    }
}
