.cardWrapper {
    height: calc(100% - 24px);
}

.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.8em;
    }

    .cardTitle {
        font-weight: bold;
        text-transform: capitalize;
    }

}
    
.cardCount {
    font-weight: normal;
    padding-left: 0.3em;
}