@import '../../assets/scss/colors';

.header {
    .title {
        font-size: 1.2em;
        color: $primary-purple;
        font-weight: 600;
    }
    .subtitle {
        font-size: 1em;
        color: $grey-light-dark;
    }
}

.link {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    a {
        font-size: 0.9em;
        color: $primary-purple;

        &:hover {
            font-weight: bold;
        }
    }
}