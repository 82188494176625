@import '../../../assets/scss/colors';

.field-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    label {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 0.2em;
        color: $dark-grey;
    }

    &.invalidField {
        .password-field-wrapper {
            input {
                border: 1px solid $critical-red;
                border-radius: 0.3em;
            }
        }
    }

    .password-field-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;

        input {
            outline: none;
            border: 1px solid $inactive-grey;
            border-radius: 0.3em;
            width: 100%;
            padding: 0.5em 1em;
            font-size: 0.85em;
            color: $gray85;
            background-color: $white !important;
    
            &:focus {
                border: 1.4px solid $border-shadow-focused;
                box-shadow: rgba(102,175,233, 0.3) 0px 1px 2px 0px, rgba(102,175,233, 0.15) 0px 2px 6px 2px;
            }
        }

        i {
            position: absolute;
            right: 1em;

            &:hover {
                cursor: pointer;
                color:$light-gray;
            }
        }
    }

    .invalidMessage {
        color: $critical-red;
        font-size: 0.85em;
        margin-top: 0.2em;
        margin-bottom: 0;
    }
}