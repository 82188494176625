@import '../../../assets/scss/colors';

.button {
    background-color: $dark-grey;
    border-radius: 4px;
    border: none;
    color: $white;      
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    transition: all 0.2s ease;
    padding: 0.7em 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;

    &.loading {
        .spinner {
            width: 1.5em;
            height: 1.5em;
        }
    }

    &.nowrap {
        white-space: nowrap;
    }
}

.mainButton {
    font-size: 16px;
    font-weight: 600;

    &:hover {
        background-color: $button-hover-grey;
    }

    &:disabled {
        background-color: $grey-button-disabled;
        color: $grey-button-disabled-text;
        
        &:hover {
            cursor: default;
            background-color: $grey-button-disabled !important;
            color: $grey-button-disabled-text !important;
        }
    }
}

.secondaryButton {
    background-color: $button-grey;
    color: $dark-grey;

    &:hover {
        background-color: $grey-light-medium-dark;
    }
}

.thirdButton {
    background-color: $button-grey;
    color: $medium-pink;

    &:hover {
        background-color: $grey-light-medium-dark;
    }
}

.regularButton {
    &:hover {
        background-color: $button-hover-grey;
    }

    &:disabled {
        background-color: $grey-button-disabled;
        color: $grey-button-disabled-text;
        
        &:hover {
            cursor: default;
            background-color: $grey-button-disabled !important;
            color: $grey-button-disabled-text !important;
        }
    }
}

.cancelButton {
    background-color: $medium-pink;
    color: $white;

    &:hover {
        background-color: $pink;
    }

    &:disabled {
        background-color: $grey-button-disabled;
        color: $grey-button-disabled-text;
        
        &:hover {
            cursor: default;
            background-color: $grey-button-disabled !important;
            color: $grey-button-disabled-text !important;
        }
    }
}

.smallButton {
    font-size: 1em;

    &:hover {
        background-color: $button-hover-grey;
    }

    &:disabled {
        background-color: $grey-button-disabled;
        color: $grey-button-disabled-text;
        
        &:hover {
            cursor: default;
            background-color: $grey-button-disabled !important;
            color: $grey-button-disabled-text !important;
        }
    }
}

.link {
    background-color: transparent;
    font-size: 1em;
    text-decoration: underline !important;
    color: $link-default !important;
    padding: 0;
    width: fit-content;

    &:hover {
        color: $link-hover !important;
    }

    &:active {
        color: $link-active !important;
    }
}