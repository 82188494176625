.error-toast--wrapper {
    position: absolute;
    right: 1%;
    top: 1%;
    animation: renderOnScreen 0.2s ease-in-out;
}

@keyframes renderOnScreen {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: none;
    }
}