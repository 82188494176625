@import '../../assets/scss/colors';

.individuals-table {

    &__table-headers {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .title {
            width: 100%;
            margin-bottom: 1em;

            h4 {
                font-size: 1.2em;
                margin: 0;
            }
        }

        .filters-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1em;
            gap: 1em;

            @media screen and (max-width: 600px) {
                flex-direction: column;
            }
        }
    }
}

.action-button {
    background-color: transparent !important;
    border: 1px solid rgb(206, 212, 218) !important;
    img {
        max-width: 30px;
    }

    &:hover {
        background-color: $button-light-grey !important;
        cursor: pointer; 
    }
}

