@import '../../../assets/scss/colors';

.modal__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;

    h5 {
        margin-bottom: 0;
        font-size: 1.2em;
        font-weight: bold;
    }

    button {
        outline: none;
        border: none;
        background-color: transparent;
        border-radius: 50px;
        &:hover {
            background-color: $button-hover-grey;
            span {
                color: $white;
            }
        }
        span {
            font-size: 1em;
        }
    }
}

.modal__content {
    padding: 0 1em 1em;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1em;
}

.dropdown__option {
    display: block;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding: 1em;
    text-align: start;

    &:hover {
        background-color: $button-light-grey;
    }
}

.modal__action-button {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    button {
        width: 40%;
    }
}