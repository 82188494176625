@import '../../../assets/scss/colors';

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.8em;

    button {
        width: 50%;
        margin-top: 0.8em;
    }

    .invalidMessage {
        color: $critical-red;
        font-size: 0.85em;
        margin-bottom: 0;
    }
}