@import '../../../assets/scss/colors';

.header-profile-user {
    height: 36px;
    width: 36px;
    background-color: $new-light-gray;
    padding: 3px;
  
    &.with-initials {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .initials {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0;
        color: $primary-purple;
      }
    }
  }