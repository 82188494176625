@import '../../colors';

.individuals-row {
    &:hover {
        background-color: rgba($primary-purple, 0.3);
    }
}

.cell__without-padding {
    padding: 0 !important;
    position: relative;
}

.split-right {
    border-right: 1px solid gray;
}


.selectedRow {
    background-color: rgba($primary-purple, 0.9);
    color: #fff;
}

.checkbox {
    &:hover {
        cursor: pointer;
    }
}

.header__sortable {
    &:hover {
        cursor: pointer;
    }
}

.studyPace {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    p {
        margin: 0 0 0 0.75rem;
    }
    &.onTrack {
        background-color: $on-track-green;
    }
    &.completed {
        background-color: $on-track-green;
    }
    &.needsAttention {
        background-color: $at-risk-yellow;
    }
    &.atRisk {
        background-color: $critical-red;
    }
    &.graduated {
        background-color: $graduated-gray;
    }
}

.progress-bar {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;

    div {
        width: 100%;
    }

    p {
        margin-bottom: 0;
        color: $primary-gray;
        font-size: 0.8em;
    }
}