@import '../../../assets/scss/colors';

.tooltipWrapper {
    position: relative;
    margin-left: 8px;
    z-index: 1000;

    &:hover {
        cursor: pointer;
        color: $primary-purple;

        .tooltipContent {
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
    }

    .tooltipContent {
        display: none;
        position: absolute;
        background-color: $primary-purple;
        color: $white;
        border-radius: 0.8em 0.8em 0.8em 0;
        left: 100%;
        bottom: 100%;
        font-size: 0.8em;
        font-weight: 400;

        &.left {
            left: 0;
            transform: translateX(-100%);
            border-radius: 0.8em 0.8em 0 0.8em;
        }

        &.bottom {
            bottom: 0;
            transform: translateY(100%);
            border-radius: 0 0.8em 0.8em 0.8em;
        }

        .tooltipText {
            margin: 0;
            padding: 0.5em 1em;
            max-width: 25vw;
            min-width: 12vw;

            &.more-than-one-line {
                min-width: 30vw;
                white-space: pre-wrap;
            }

            p {
                margin-bottom: 0.2em;
                line-height: 1.3em;
                text-align: left;
                white-space: pre-wrap;
            }
        }
    }
}