@import '../../../assets/scss/colors';

.arrowsWrapper {
    display: inline-flex;
    transform: translateY(-25%);
    flex-direction: column;
    margin-left: 10px;
    
    .arrowUp, .arrowDown {
        width: 7px;
        height: 7px;
        border-top: 1px solid $grey-light-button;
        border-right: 1px solid $grey-light-button;
        padding: 2px;

        &.selected {
            border-top: 2px solid black;
            border-right: 2px solid black;
        }

        &:hover {
            cursor: pointer;
            border-top: 2px solid black;
            border-right: 2px solid black;
        }
    }

    .arrowUp {
        transform: rotate(-45deg);
    }

    .arrowDown {
        transform: rotate(135deg);
    }
}