.wrapper {
    display: flex;
    justify-content: flex-start;     
    align-items: center;   
    
    @media only screen and (max-width: 600px) {
        flex-direction: column;
        gap: 1em;
    }

    .filters__wrapper {
        display: flex;
        align-items: center;
        gap: 1em;
        margin: 0 1em !important;
    
        @media only screen and (max-width: 600px) {
            flex-direction: column;
            margin: 0 !important;
        }
    
        select {
            min-width: 12em;
        }
    }
}
