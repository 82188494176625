@import '../../../assets/scss/colors';

.wrapper {
    .label {
        font-size: 1em;
        font-weight: 700;
        margin-bottom: 0.2em;
        color: $dark-grey;
    }
    
    .dropdown {
        .button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 0.8em;
    
            img {
                margin: 0 auto;
            }

            i {
                transition: transform 0.3s;
                &.rotateCaret {
                    transform: rotate(180deg);
                }
            }
            .closeButton {
                outline: none;
                border: none;
                background-color: transparent;        
                border-radius: 50px;
                position: absolute;
                right: 30px;
                transform: translateY(-10%);
                &:hover {
                    background-color: $button-hover-grey;
                    span {
                        color: $white;
                    }
                }
            }
        }

    
        p {
            color: #7C8093;
            margin-bottom: 0;
            white-space: nowrap;
        }
    
        div {
            z-index: 2000;
        }
    
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
}

.items-wrapper {
    max-height: 30vh;
    overflow-y: auto;
    padding: 1em !important;

    div:last-of-type {
        margin-bottom: 0 !important;
    }
}
