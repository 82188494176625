@import '../../../assets/scss/colors';

.cardWrapper {
    height: calc(100% - 24px);
    font-size: 0.8em;

    .cardBody {
        border: 1px solid $primary-purple;
        border-radius: 0.5em;
        padding: 0.2em 1em;
    }

    .positive {
        background-color: $text-highlight;
    }

    .neutral {
        background-color: $white;
    }

    .negative {
        background-color: $tuition-color;
    }

    .cardHeader {
        .cardTitle {
            font-size: 0.9em;
            font-weight: bold;
            text-transform: capitalize;
        }
    }

    .cardNumbers {
        display: flex;
        align-items: center;
        gap: 0.5em;
        .cardValue {
            font-weight: normal;
            font-size: 1.4em;
            padding-left: 0.3em;
        }
    
        .cardGrowth {
            margin-top: 15%;
        }
    }
}
